<template>
  <div class="bg">
    <div class="container">
      <div class="auth-wrapper auth-v1">
        <div class="auth-inner">
          <!-- Login v1 -->
          <div class="mb-0">
            <div
              v-if="!ag"
              class="warning-show"
            >
              <img
                src="/warning.png"
                alt=""
                height="80"
                class="mb-2"
              >
              <br>
              <p>URL ไม่ถูกต้อง กรุณาติดต่อผู้แนะนำ</p>
            </div>
            <div
              v-else
              class="LoginForm"
            >
              <div class="text-center">
                <b-img
                  src="/logo/logo.png"
                  alt="logo"
                  height="95"
                />
              </div>
              <!-- กรอกเบอร์โทรศัพท์ -->
              <div v-if="!ref">
                <div>
                  <b-form-group>
                    <label>เบอร์โทรศัพท์</label>
                    <b-form-input
                      v-model="tel"
                      name="tel"
                      type="number"
                      placeholder="กรุณากรอกเบอร์โทรศัพท์"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      maxlength="11"
                    />
                    <small
                      class="text-warning"
                    >*กรุณากรอกเบอร์โทรศัพท์ 10 หรือ 11 หลัก</small>
                  </b-form-group>
                </div>

                <div class="text-center mt-2">
                  <!-- <button
                  class="btn-hover color-1"
                  block
                  @click="getOTP"
                >
                  รับ OTP
                </button> -->
                  <button
                    class="btnlogin"
                    @click="getOTPs"
                  >
                    ยืนยัน
                  </button>
                </div>
              </div>

              <!-- OTP -->
              <div v-if="ref && !match">
                <div class="row">
                  <div class="col-4">
                    <div>
                      <label>&nbsp;</label><br>
                      <vue-captcha
                        ref="captcha"
                        :captcha.sync="code"
                        font-weight="bold"
                        @on-change="handleChange"
                      />
                    </div>
                  </div>
                  <div class="col-8">
                    <b-form-group>
                      <label
                        for="password"
                      >รหัส Captcha
                        <small
                          class="text-warning"
                        >ref : {{ ref }}</small></label>
                      <validation-provider
                        #default="{ errors }"
                        name="otp"
                      >
                        <b-form-input
                          id="otp"
                          v-model="otp"
                          name="otp"
                          type="otp"
                          placeholder="กรุณากรอกตัวอักษรที่แสดง"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          :state="errors.length > 0 ? false : null"
                          maxlength="4"
                          @keydown.enter.prevent="checkOTP"
                        />
                        <small class="text-warning">{{
                          errors[0] ? "กรุณากรอกรหัส OTP 6 หลัก" : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>

                <button
                  class="btnlogin"
                  @click="checkOTP"
                >
                  ยืนยัน
                </button>
              </div>

              <!-- Confirm -->
              <div v-if="!next && match">
                <!-- <div> -->
                <validation-observer ref="simpleRules">
                  <div class="mt-2">
                    <b-row>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="fg">ชื่อ</label>
                          <validation-provider
                            #default="{ errors }"
                            name="ชื่อ"
                            rules="required"
                          >
                            <b-form-input
                              v-model="name"
                              placeholder="กรุณากรอกชื่อ"
                              :state="errors.length > 0 ? false : null"
                              type="text"
                              size="lg"
                            />
                            <small class="text-warning">{{
                              errors[0] ? `กรุณากรอกชื่อ` : ''
                            }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="fg">นามสกุล</label>
                          <validation-provider
                            #default="{ errors }"
                            name="นามสกุล"
                            rules="required"
                          >
                            <b-form-input
                              v-model="surname"
                              :state="errors.length > 0 ? false : null"
                              placeholder="กรุณากรอกนามสกุล"
                              type="text"
                              size="lg"
                            />
                            <small class="text-warning">{{
                              errors[0] ? `กรุณากรอกนามสกุล` : ''
                            }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <small
                      class="text-warning"
                    >*กรอกชื่อ-นามสกุล ให้ตรงกับชื่อบัญชี หรือ
                      ทรูมันนี่วอลเล็ท</small>

                    <b-form-group>
                      <label class="fg">กรอกหมายเลขทรูมันนี่วอลเล็ท</label>
                      <b-form-input
                        v-model="trueaccno"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="10"
                        placeholder="กรุณากรอกหมายเลขทรูมันนี่วอลเล็ท"
                        size="lg"
                        type="number"
                        @input="CheckBank()"
                      />
                      <small class="text-warning">{{
                        ErrorBank ? `กรอกหมายเลขทรูมันนี่วอลเล็ท` : ''
                      }}</small>
                    </b-form-group>

                    <b-form-group class="mt-1">
                      <label class="fg">เลือกธนาคาร</label>
                      <div
                        class="select_bank"
                        :style="`border: 2px solid ${
                          selected ? selected.bg : '#b4b7bd'
                        };`"
                        @click="showBank"
                      >
                        <img
                          :src="`/bankIcon/${
                            selected ? selected.path_photo : 'bk.png'
                          }`"
                          :style="`background-color: ${
                            selected ? selected.bg : '#FF6700'
                          };`"
                          class="bank_icon"
                        >

                        <span>{{ selected ? selected.name : "กรุณาเลือกธนาคาร" }}</span>
                      </div>
                      <small class="text-warning">{{
                        ErrorBank ? `กรุณาเลือกธนาคารให้ถูกต้อง` : ''
                      }}</small>
                    </b-form-group>
                    <b-form-group>
                      <label class="fg">กรอกบัญชีธนาคาร</label>
                      <b-form-input
                        v-model="accno"
                        placeholder="กรุณากรอกเลขบัญชี"
                        size="lg"
                        type="number"
                        @input="CheckBank()"
                      />
                      <small class="text-warning">{{
                        ErrorBank ? `กรุณากรอกเลขบัญชีให้ถูกต้อง` : ''
                      }}</small>
                    </b-form-group>

                    <b-form-group>
                      <label for="bank">เลือกธนาคารลาว</label>
                      <div
                        class="select_bank2"
                        :style="`border: 2px solid ${
                          selected_laos ? selected_laos.bg : '#b4b7bd'
                        };`"
                        @click="showModal"
                      >
                        <img
                          :src="`/bankIcon/${
                            selected_laos ? selected_laos.path_photo : 'bk.png'
                          }`"
                          :style="`background-color: ${
                            selected_laos ? selected_laos.bg : '#FF6700'
                          };`"
                          class="bank_icon"
                        >
                        <span>{{
                          selected_laos ? selected_laos.name_lao : "กรุณาเลือกธนาคารลาว"
                        }}</span>
                      </div>
                      <small class="text-warning">{{
                        ErrorBank ? `กรุณาเลือกธนาคารให้ถูกต้อง` : ''
                      }}</small>
                    </b-form-group>

                    <!-- <b-form-group>
                      <label class="fg">ประเภทบัญชีธนาคารลาว :</label>
                      <b-form-select
                        v-model="bank_laos_type"
                        size="sm"
                        :options="bank_laos_type_list"
                        placeholder=""
                        @change="CheckBank()"
                      />
                      <small class="text-danger">{{
                        ErrorBank ? `กรุณาเลือกประเภทบัญชีธนาคารลาวให้ถูกต้อง` : ''
                      }}</small>
                    </b-form-group> -->

                    <b-form-group>
                      <label for="accno">เลขที่บัญชีธนาคารลาว</label>
                      <b-form-input
                        v-model="acc_laos"
                        name="accno"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="18"
                        type="number"
                        @input="CheckBank()"
                      />
                      <small class="text-warning">{{
                        ErrorBank ? `กรุณากรอกเลขบัญชีให้ถูกต้อง` : ''
                      }}</small>
                    </b-form-group>

                    <b-form-group>
                      <label class="fg">รู้จักเราผ่านช่องทาง :</label>
                      <b-form-select
                        v-model="knowby"
                        size="lg"
                        :options="options"
                        placeholder=""
                      />
                    </b-form-group>
                    <!-- <b-form-group>
                    <label class="fg">ยูสเซอร์</label>
                    <p>{{ username }}</p>
                  </b-form-group> -->
                    <b-form-group>
                      <label class="fg">กรอกรหัสผ่าน</label>
                      <validation-provider
                        #default="{ errors }"
                        name="รหัสผ่าน"
                        rules="required"
                      >
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            id="basic-password1"
                            v-model="password"
                            size="lg"
                            :state="errors.length > 0 ? false : null"
                            :type="passwordFieldType"
                            placeholder="กรุณากรอกรหัสผ่าน"
                          />
                          <b-input-group-append
                            is-text
                            size="lg"
                          >
                            <feather-icon
                              :icon="passwordToggleIcon"
                              class="cursor-pointer"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-warning">{{
                          errors[0] ? `กรุณากำหนดรหัสผ่านของท่าน` : ''
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- <b-form-checkbox
                      v-model="promo"
                      checked="fasle"
                      name="check-button"
                      switch
                      inline
                      class="custom-control-warning"
                    >
                      รับโปรโมชั่น
                    </b-form-checkbox>
                    <b-form-group v-if="promo === true">
                      <b-form-radio-group
                        v-model="promotion"
                        :options="promotions"
                        name="radios-stacked"
                        stacked
                        class="custom-control-warning"
                      />
                    </b-form-group> -->
                  </div>

                  <div class="text-center mt-1">
                    <!-- <button
                    class="btn-hover color-1"
                    block
                    @click.prevent="validationForm"
                  >
                    ยืนยันข้อมูล
                  </button> -->
                    <button
                      :disabled="checkSubmit === true"
                      class="btnlogin"
                      @click.prevent="validationForm"
                    >
                      ยืนยันข้อมูล
                    </button>
                  </div>
                </validation-observer>
              </div>
              <div class="text-center mt-2">
                <span
                  style="color: #D2E536"
                >ต้องการเข้าสู่ระบบใช่ไหม?<a
                  :href="`/login`"
                  style="color: #fff"
                >
                  เข้าสู่ระบบ</a></span>
              </div>
            </div>
            <b-modal
              id="modal-sm"
              cancel-variant="secondary"
              ok-only
              ok-title="ส่งรหัสผ่าน"
              centered
              size="sm"
              title="ลืมรหัสผ่าน"
            >
              <b-input-group
                class="input-group-merge"
                title="sss"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="PhoneCallIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="tel"
                  type="number"
                  placeholder="กรุณากรอกเบอร์โทรศัพท์"
                />
              </b-input-group>
              <small>*กรุณากรอกเบอร์โทรศัพท์ที่ท่านทำการสมัครไว้</small>
            </b-modal>

            <b-modal
              ref="my-bank"
              centered
              title="Vertically Centered"
              hide-footer
              hide-header
              hide-header-close
            >
              <div class="row">
                <div
                  v-for="option in bank"
                  :key="option.bankcode"
                  class="col-4 mb-2 mt-1 text-center cursor-pointer"
                  @click="select(option)"
                >
                  <b-avatar
                    size="80"
                    :src="`/bankIcon/${option.path_photo}`"
                    :style="`background-color: ${option.bg}; padding: 10px; border: 4px solid #1e110d66; border-radius: 0;`"
                  />
                  <p style="color: #000">
                    {{ option.codename }}
                  </p>
                </div>
              </div>
            </b-modal>

            <b-modal
              ref="my-modal"
              centered
              title="Vertically Centered"
              hide-footer
              hide-header
              hide-header-close
            >
              <div class="row">
                <div
                  v-for="option in bank_laos_list"
                  :key="option.bankcode"
                  class="col-4 mb-2 mt-1 text-center"
                  @click="selectLaos(option)"
                >
                  <b-avatar
                    size="80"
                    :src="`/bankIcon/${option.path_photo}`"
                    :style="`background-color: ${option.bg}; padding: 10px; border: 4px solid #1e110d66; border-radius: 0;`"
                  />
                  <p style="color: #000">
                    {{ option.bankcode }}
                  </p>
                  <p style="color: #000">
                    {{ option.name_lao }}
                  </p>
                </div>
              </div>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BImg,
  BRow,
  BCol,
  // BCarousel,
  // BCarouselSlide,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  // BFormCheckbox,
  // BFormRadioGroup,
  BModal,
  BAvatar,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import OtpInput from '@8bu/vue-otp-input'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import VueCaptcha from 'vue-captcha-code'

export default {
  components: {
    BImg,
    BRow,
    BCol,
    // BCarousel,
    // BCarouselSlide,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    // BFormCheckbox,
    // BFormRadioGroup,
    // OtpInput,
    ValidationProvider,
    ValidationObserver,
    VueCaptcha,
    BModal,
    BAvatar,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      checkSubmit: false,
      ag: 12,
      URLag: this.$route.query.ag,
      name: '',
      required,
      wallet: '',
      tel: '',
      trueaccno: '',
      otp: '',
      code: '',
      ref: null,
      match: null,
      next: null,
      selected: null,
      s_bank: null,
      accno: '',
      password: '',
      knowby: null,
      username: '',
      promo: '',
      promotion: '',
      bank: [{ value: null, text: 'กรุณาเลือกธนาคาร' }],
      bank_laos_list: [{ value: null, text: 'กรุณาเลือกธนาคาร' }],
      bank_laos: null,
      bank_laos_type: 1,
      acc_laos: '',
      selected_laos: null,
      selects: null,
      options: [
        { value: null, text: 'คุณรู้จักเราผ่านช่องทางใด' },
        { value: 1, text: 'Facebook' },
        { value: 2, text: 'Youtube' },
        { value: 3, text: 'Tik Tok' },
        { value: 4, text: 'Google' },
        { value: 5, text: 'SMS' },
        { value: 6, text: 'เพื่อนแนะนำ' },
      ],
      bank_laos_type_list: [
        { value: null, text: 'ประเภทบัญชีธนาคารลาว' },
        { value: 1, text: 'กีบ' },
        { value: 2, text: 'บาท' },
      ],
      promotions: [
        { text: 'สมัครใหม่ รับ 50% สูงสุด 500', value: 3 },
        // {
        //   text: 'เครดิตฟรี 100',
        //   value: 7,
        // },
      ],
      ErrorBank: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    // console.log(this.$route.query.ref)
    // console.log(this.$route.query.test)
    this.getBank()
    this.getBankLaos()
  },
  methods: {
    CheckBank() {
      if (this.trueaccno.length > 0 || (this.s_bank && this.accno.length > 0) || (this.bank_laos && this.acc_laos.length > 0 && this.bank_laos_type)) {
        this.ErrorBank = false
      } else {
        this.ErrorBank = true
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        this.CheckBank()
        if (success && !this.ErrorBank) {
          if (this.promo === false) {
            this.promotion = null
          }
          // if (this.wallet === true) {
          //   this.s_bank = 'truewallet'
          //   this.accno = null
          // } else {
          //   this.trueaccno = null
          // }
          // console.log(this.ag)
          this.checkSubmit = true
          const formData = {
            // username: `HIT${this.tel}`,
            agent_id: this.ag,
            name: this.name,
            surname: this.surname,
            invited_by: this.$route.query.ref,
            tel: this.tel,
            bank: this.s_bank,
            acc_no: this.accno,
            bank_laos: this.bank_laos,
            acc_laos: this.acc_laos,
            password: this.password,
            knowby: this.knowby,
            main_promotion: this.promotion,
            trueaccno: this.trueaccno,
            bank_laos_type: this.bank_laos_type,
          }
          this.$http
            .post('https://api.ufabangkok.com/api/users/store', formData)
            .then(() => {
              // console.log(res)
              useJwt
                .login({
                  email: this.tel,
                  password: this.password,
                  agent_id: this.ag,
                  Detail: {
                    Ip: this.Ip,
                    UserAgent: navigator.userAgent,
                    Platform: navigator.platform,
                  },
                })
                .then(response => {
                  this.checkSubmit = false
                  const userData = response.data
                  useJwt.setToken(response.data.token)
                  useJwt.setRefreshToken(response.data.refreshToken)
                  localStorage.setItem('userData', JSON.stringify(userData))
                  localStorage.setItem('CheckClick', true)
                  this.$ability.update(userData.ability)
                  this.$http
                    .get('https://api.ufabangkok.com/api/auth/checklogin')
                    .then(res => {
                      localStorage.setItem('CountLogin', res.data)
                    })
                  this.$router
                    .push(getHomeRouteForLoggedInUser(userData.role))
                    .catch(error => {
                      this.$refs.loginForm.setErrors(error.response.data.error)
                    })
                })
                .catch(error => {
                  this.checkSubmit = false
                  this.$refs.loginForm.setErrors(error.response.data.error)
                })
            })
            .catch(error => {
              this.checkSubmit = false
              this.SwalError(error.response.data.message)
            })
        }
      })
    },
    handleOnComplete(value) {
      console.log('OTP completed: ', value)
    },
    handleOnChange(value) {
      console.log('OTP changed: ', value)
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput()
    },
    select(option) {
      this.$refs['my-bank'].hide()
      this.selected = option
      this.s_bank = option.bankcode
      this.CheckBank()
    },
    getBank() {
      this.$http
        .get('https://api.ufabangkok.com/api/bank/list')
        .then(response => {
          // console.log(response.data)
          this.bank = response.data
        })
        .catch(error => {
          console.log(error.response.data.message)
        })
    },
    showBank() {
      this.$refs['my-bank'].show()
    },
    hideBank() {
      this.$refs['my-bank'].hide()
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    selectLaos(option) {
      this.$refs['my-modal'].hide()
      this.selected_laos = option
      this.bank_laos = option.bankcode
      this.CheckBank()
    },
    getBankLaos() {
      this.$http
        .get('https://api.ufabangkok.com/api/bank/list/laos')
        .then(response => {
          // console.log(response.data)
          this.bank_laos_list = response.data
        })
        .catch(error => {
          console.log(error.response.data.message)
        })
    },
    getOTPs() {
      if (this.tel.length < 10) {
        this.SwalError(
          'กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 หรือ 11 หลัก',
        )
      } else {
        const formData = {
          tels: this.tel,
          agent_id: this.ag,
        }
        this.$http
          .post('https://api.ufabangkok.com/api/otp/checktel', formData)
          .then(response => {
            this.ref = response.data.ref
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      }
    },
    checkOTP() {
      if (this.code === this.otp) {
        this.match = true
      } else {
        this.$refs.captcha.refreshCaptcha()
        this.SwalError('รหัส Captcha ไม่ถูกต้อง')
      }
    },
    // async submit() {
    //   if (this.promo === false) {
    //     this.promotion = null
    //   }
    //   const formData = {
    //     username: `HIT${this.tel}`,
    //     name: this.name,
    //     surname: this.surname,
    //     invited_by: this.$route.query.ref,
    //     tel: this.tel,
    //     bank: this.s_bank,
    //     acc_no: this.accno,
    //     password: this.password,
    //     knowby: this.knowby,
    //     main_promotion: this.promotion,
    //     trueaccno: this.trueaccno,
    //   }
    //   this.$http
    //     .post('http://127.0.0.1:3030/api/users/store', formData)
    //     .then(() => {
    //       // console.log(res)
    //       useJwt
    //         .login({
    //           email: this.tel,
    //           password: this.password,
    //           agent_id: this.ag,
    //           Detail: {
    //             Ip: this.Ip,
    //             UserAgent: navigator.userAgent,
    //             Platform: navigator.platform,
    //           },
    //         })
    //         .then(response => {
    //           const userData = response.data
    //           useJwt.setToken(response.data.token)
    //           useJwt.setRefreshToken(response.data.refreshToken)
    //           localStorage.setItem('userData', JSON.stringify(userData))
    //           this.$ability.update(userData.ability)
    //           this.$router
    //             .push(getHomeRouteForLoggedInUser(userData.role))
    //             .catch(error => {
    //               this.$refs.loginForm.setErrors(error.response.data.error)
    //             })
    //         })
    //         .catch(error => {
    //           this.$refs.loginForm.setErrors(error.response.data.error)
    //         })
    //     })
    //     .catch(error => {
    //       this.SwalError(error.response.data.message)
    //     })
    // },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: 'SUCCESS',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color:#000">ขออภัยค่ะ!<span>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}
</script>

<style scoped>
label {
  color: #D2E536;
  font-size: 1.1rem;
}
.bg {
  background-image: url(/logo/bg.jpg);
  background-size: cover;
  background-position: center center;
}
.LoginForm {
    width: 100%;
    padding: 11px 30px 20px 30px;
    border-radius: 30px;
    background: #193D61;
}
.btnlogin {
    /* padding: 0.75em; */
    background: #EE7524;
    color: rgb(255, 255, 255);
    border-radius: 18px !important;
    width: 100%;
    height: 43px;
    font-size: 1.3rem;
    border: none;
    text-transform: uppercase;
    font-weight: 400;
    display: inline-block;
    text-align: center;
}
.warning-show {
  color: #fff;
  text-align: center;
  background: linear-gradient(to bottom, #7222416e, #58585870);
  border-radius: 10px;
  padding: 15px;
  border: rgba(255, 255, 255, 0.527) solid 4px;
  box-shadow: #8826ff94 0px 5px 10px 5px;
}

</style>
<style lang="scss">
@import url('https://unpkg.com/@8bu/vue-otp-input@1.1.6/dist/vue-otp-input.css');
@import '@core/scss/vue/pages/page-auth.scss';
.style-1 {
  // background-color: #141414;
  .otp-input-8-field input {
    border-radius: 4px;
    color: #ffc800;
    height: 37px;
    width: 37px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #fcfcfc;
    border: 1px solid #f5cd79;
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
.form-control {
  padding: 0.438rem 1rem;
  background: #fff !important;
  background-clip: padding-box;
  border: 0;
  height: 43px;
  border-radius: 18px;
}
.input-group-text {
  padding: 0.438rem 1rem;
  height: 43px;
  margin-bottom: 0;
  text-align: center;
  background: white !important;
  border: 0;
  border-radius: 18px;
}
.custom-select {
  border: 0;
  border-radius: 18px;
}
.select_bank {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 45px;
  border-radius: 14px;
  background-color: #000000;
  color: #fff;
  border: 1px solid #000000;
  max-width: 100%;
  padding: 0.2rem 0.7rem;
  filter: drop-shadow(0 0 1px #5c5c5c);
}
.select_bank2 {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 45px;
  border-radius: 14px;
  background-color: #000000;
  color: #fff;
  border: 1px solid #000000;
  max-width: 100%;
  padding: 0.2rem 0.7rem;
  filter: drop-shadow(0 0 1px #5c5c5c);
}
.bank_icon {
  width: 32px;
  border-radius: 15rem;
  padding: 5px;
  margin-right: 10px;
}
</style>
